import styles from 'components/SimilarTrials/SimilarTrials.module.scss';
import Client from 'api/client';
import TrialCard from 'components/TrialCard/TrialCard';
import Button from '@moonshineragency/ui/src/components/Button/Button';
import Heading from '@moonshineragency/ui/src/components/Heading/Heading';
import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';

const SimilarTrials = ({ formValues, trialModified }) => {
  const [trials, setTrials] = useState([]);

  const isNoneOfSearchSelected = !!(
    formValues?.injuryLevel?.length === 0 &&
    formValues?.severity === null &&
    formValues?.time_since_injury === null
  );
  useEffect(() => {
    const searchValues = isNoneOfSearchSelected
      ? { sort_by: 'updated' }
      : formValues;

    const searchTrials = async () => {
      try {
        const {
          data: { data },
        } = await Client.trials.search(1, searchValues);

        setTrials([...data?.slice(0, 3)]);
      } catch (e) {
        setTrials([]);

        // eslint-disable-next-line no-console
        console.error(e);
      }
    };

    searchTrials();
  }, [formValues]);
  return (
    <div>
      <Heading theme="primary" align="center" size="h2">
        Curated trials you might be interested in
      </Heading>
      <div className={styles.trials}>
        {trials.map(
          ({
            trial: {
              nct_id: nctId,
              brief_title: briefTitle,
              about_trial: aboutThisTrial,
              modified_at: modifiedDate,
              primary_intervention: primaryIntervention,
              primary_benefit: primaryBenefit,
              curation_status: curationStatus,
              overall_recruitment_status: recruitingStatus,
            },
          }) => (
            <TrialCard
              key={nctId}
              to={`/trials/${nctId}`}
              title={briefTitle}
              content={aboutThisTrial}
              recruitingStatus={recruitingStatus}
              location="Vienna, Austria"
              primaryBenefit={primaryBenefit}
              primaryIntervention={primaryIntervention}
              curation={curationStatus}
              modifiedDate={trialModified || modifiedDate}
            />
          ),
        )}
      </div>

      <div className={styles.showMoreTrials}>
        <Button
          size="default"
          theme="secondary"
          onClick={() => navigate('/trials')}
          isFullWidth
        >
          More curated trials
        </Button>
      </div>
    </div>
  );
};

export { SimilarTrials as default };
