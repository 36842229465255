import styles from 'templates/confirmation/confirmation.module.scss';
import Container from 'components/Container/Container';
import Layout from 'components/Layout/Layout';
import Client from 'api/client';
import { getSearchedCriteria } from 'services/getSearchedCriteria';
import { SearchSettingsContext } from 'context';
import SimilarTrials from 'components/SimilarTrials/SimilarTrials';
import useQueryParams from 'hooks/useQueryParams';
import { navigate, useStaticQuery, graphql } from 'gatsby';
import IconComponents from '@moonshineragency/ui/src/components/IconComponents/IconComponents';
import React from 'react';
import IconWrapper from '@moonshineragency/ui/src/components/IconWrapper/IconWrapper';

import Heading from '@moonshineragency/ui/src/components/Heading/Heading';
import Content from '@moonshineragency/ui/src/components/Content/Content';
import Button from '@moonshineragency/ui/src/components/Button/Button';

const handleUnsubscribe = async email => {
  try {
    await Client.unsubscribeNotifications.get(email);
    navigate('/');
  } catch (error) {
    alert('Something went wrong. Please try later again.');
  }
};

const ConfirmationTemplate = () => {
  const context = React.useContext(SearchSettingsContext);
  const formValues = context?.form?.values;
  const [email] = useQueryParams('email');

  const {
    allBenefit: { edges },
    allIntervention: { edges: interventionEdges },
  } = useStaticQuery(graphql`
    query ConfirmationPageQuery {
      allBenefit {
        edges {
          node {
            id
            name
            label
          }
        }
      }
      allIntervention {
        edges {
          node {
            id
            name
            label
          }
        }
      }
    }
  `);

  const benefits = edges.map(({ node }) => ({ ...node, num: 0 }));
  const interventions = interventionEdges.map(({ node }) => ({
    ...node,
    num: 0,
  }));

  const filteredSearchCriteria = getSearchedCriteria(
    formValues,
    benefits,
    interventions,
  );
  return (
    <Layout title="Confirmation" onlyShowSearchIcon isContentpage>
      <Container size="lg">
        <div className={styles.confirmationHeading}>
          <div className={styles.imageWrapper}>
            <IconWrapper
              Icon={IconComponents.LargeCheckIcon}
              className={styles.largeCheck}
            />
          </div>
          <Heading theme="primary" align="center" size="h1big" noSpacing>
            Email notifications set
          </Heading>
        </div>
        <div className={styles.confirmationTextWrapper}>
          <Content size="md" className={styles.confirmationText}>
            We’ll send you an email whenever there are new trials matching the
            following criteria.
          </Content>
        </div>
        <ul className={styles.selectedWrapper}>
          {filteredSearchCriteria.map(item => (
            <li className={styles.selectedItem} key={item}>
              {item}
            </li>
          ))}
        </ul>
        <div className={styles.actionContainer}>
          <Button theme="link" onClick={() => handleUnsubscribe(email)}>
            Unsubscribe from these notifications
          </Button>
        </div>
        <SimilarTrials formValues={formValues} />
      </Container>
    </Layout>
  );
};

export default ConfirmationTemplate;
